import React, { useState } from "react";
import { useIntl } from "react-intl";

import ErrorDialog from "@components/ErrorDialog";
import DataTable from "@components/DataTableV2/DataTable";

import { ROUTES } from "@utils/constant";
import { EMAIL_TYPE, EMAIL_CLASSIFICATION_TYPE } from "@utils/enum";

const SEARCHABLE_FIELDS = ["id", "from", "to"];

const EmailLogListTab = () => {
  const { messages } = useIntl();
  const FILTER_KEY_FROM = "filter[created_at][gte]";
  const FILTER_KEY_TO = "filter[created_at][lte]";
  const [errorMessage, setErrorMessage] = useState("");

  const handleCloseErrorDialog = () => {
    setErrorMessage("");
  };

  const redirectToEmailLogDetails = ({ id }) => {
    return ROUTES.ADMIN_EMAIL_LOG_DETAILS.URL.replace(":email_id", id);
  };

  const handleRemoveEmail = () => {
    return "";
  };

  const dataTableConfig = {
    header: {
      actions: [],
      dataTableActions: [
        {
          id: "filter",
          type: "button-tooltip",
          icon: "filter",
          variant: "header",
          borderRadius: 0,
          content: messages.filter_description,
        },
      ],
    },
    defaultappliedFilters: {
      "filter[email_reply_classification]": [
        EMAIL_CLASSIFICATION_TYPE.NO_CRIMINAL_CASES,
        EMAIL_CLASSIFICATION_TYPE.HAS_CRIMINAL_CASES,
      ],
    },
    enable_filter: true,
    is_filter_options_updated: true,
    filters: [
      {
        title: messages.label_date,
        id: "calendar",
        type: "calendar",
        options: [
          {
            label: messages.calendar_date_select_placeholder,
            value: "",
            id: [FILTER_KEY_FROM, FILTER_KEY_TO],
          },
        ],
      },
      {
        title: messages.label_status,
        id: "filter[status]",
        type: "checkbox",
        options: [
          {
            label: messages.label_failed,
            value: "failed",
            checked: false,
          },
          {
            label: messages.label_sent,
            value: "sent",
            checked: false,
          },
        ],
      },
      {
        title: messages.type_label,
        id: "filter[direction]",
        type: "checkbox",
        options: [
          {
            label: messages.outgoing,
            value: EMAIL_TYPE.OUTGOING,
            checked: false,
          },
          {
            label: messages.incoming,
            value: EMAIL_TYPE.INCOMING,
            checked: false,
          },
        ],
      },
      {
        title: messages.type_label,
        id: "filter[email_reply_classification]",
        type: "checkbox",
        options: [
          {
            label: messages.no_criminal_cases,
            value: EMAIL_CLASSIFICATION_TYPE.NO_CRIMINAL_CASES,
            checked: true,
          },
          {
            label: messages.has_criminal_cases,
            value: EMAIL_CLASSIFICATION_TYPE.HAS_CRIMINAL_CASES,
            checked: true,
          },
          {
            label: messages.automatic_reply,
            value: EMAIL_CLASSIFICATION_TYPE.AUTOMATIC_REPLY,
            checked: false,
          },
        ],
      },
    ],
    enable_csv_download: false,
    backend_querying: true,
    no_records_message: "no_results_found",
    enable_search: true,
    enable_query_builder: true,
    api: {
      resource: "/email-messages",
      method: "GET",
      search_fields: SEARCHABLE_FIELDS,
      params: [["include", ["casesSourceData"]]],
    },
    default_sort: {
      field: "created_at",
      order: "desc",
    },
    columns: [
      {
        db_field: "id",
        title: "title_id",
        type: "id",
        width: "60px",
        plainText: true,
      },
      {
        db_field: "created_at",
        title: "label_registered_at",
        type: "dateTimeFull",
        width: "15%",
      },
      {
        db_field: "outgoing_email_message_id",
        title: "label_parent_id",
        type: "id",
        width: "9%",
        plainText: true,
      },
      {
        db_field: "from",
        title: "filter_date_from",
        type: "text",
        width: "20%",
      },
      {
        db_field: "to",
        title: "filter_date_to",
        type: "text",
        width: "20%",
      },
      {
        db_field: "direction",
        title: "type_label",
        type: "enum",
        width: "7%",
      },
      {
        db_field: "case_source_data_id",
        title: "label_source_data_id",
        type: "text",
        width: "8%",
      },
      {
        db_field: "status",
        title: "label_status",
        type: "text",
        width: "100px",
        formatter: value =>
          value === "failed" ? messages.label_failed : messages.label_sent,
      },
      {
        db_field: "cases_source_data.case_id",
        title: "title_cases_number",
        type: "text",
        width: "7%",
      },
      {
        type: "actions",
        width: "5%",
        actions: [
          {
            label: messages.label_email_details,
            icon: "icon-headerarrowright",
            type: "link",
            href: redirectToEmailLogDetails,
          },
          {
            label: messages.label_delete_email,
            type: "button",
            icon: "icon-rubber",
            onClick: handleRemoveEmail,
          },
        ],
      },
    ],
  };

  return (
    <>
      {errorMessage && (
        <ErrorDialog
          errorMessage={errorMessage}
          onHide={handleCloseErrorDialog}
          onConfirm={handleCloseErrorDialog}
        />
      )}

      <DataTable config={dataTableConfig} />
    </>
  );
};

export default EmailLogListTab;
